import logo from './logo.svg';
import './App.css';
import Charts from './Charts'
import React, { useEffect } from 'react';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1

        >
          Monkeypox Data
        </h1>
        <Charts>

        </Charts>
      </header>
    </div>
  );
}

export default App;
