// src/components/NavBar.js

import React from "react";

const NavBar = ({ cases, rValue }) => {
  let userLang = navigator.language || navigator.userLanguage;

  let getStyle = (name) => {
    let color = {
      Cases: "#75b989",
      RValue: "#ff9900",
    };
    return {
      border: "1px solid #fff",
      fontWeight: "500",
      color: color[name],
      width: "300px",
      height: "80px",
      textDecoration: "none",
      borderRadius: "5px",
      margin: "auto",
    };
  };
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(310px, 1fr))",
          alignItems: "center",
          justifyItems: "center",
          width: '90vw',
          marginBottom: "25px"
        }}
      >
        <div style={getStyle("Cases")}>
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            Cases
          </div>
          {(cases || 0).toLocaleString(userLang)}
        </div>
        <div style={getStyle("RValue")}>
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            Estimated R Value
          </div>
          {(rValue || 0).toLocaleString(userLang)}
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default NavBar;
