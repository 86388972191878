import logo from './logo.svg';

import React, { useEffect } from 'react';
import './App.css';

import Select from "react-select";

import axios from "axios";



const Selector = ({
  countries,
  setSelectedCountry,
  selectedCountry
}) => {

  console.log("gredsafasd" + selectedCountry);
  let options = [
    {
      value: 'All', label: 'All'
    },
    ...countries
      .filter((v) => v !== "All")
      .sort()
      .map((s) => ({ value: s, label: s }))];
  console.log(options);

  return (

    <div
      style={{
        textAlign: "left important!",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        maxHeight: "50px"
      }}
    >
      <label style={{ marginRight: "0px", marginLeft: "20px" }}>
        <span style={{ marginRight: "29px" }}>Country:</span>
        <div
          style={{
            color: "black",
            borderRadius: "10px",
            display: "inline-block",
            marginRight: "0px",
            marginBottom: "40px",
            height: "8px",
            minWidth: "250px",
            maxWidth: "350px",
          }}
        >
          <Select
            isSearchable
            value={selectedCountry}
            onChange={async (e) => {
              setSelectedCountry(e);
            }}
            options={options ? options : []}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
      </label>
    </div>

  );
}

export default Selector;
