import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import './App.css';
import {
  LineChart,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Legend,
} from "recharts";
import Selector from './Selector';
import DataBar from './DataBar'
import axios from "axios";



function Charts() {

  let getMonkeyPoxData = async () => {
    let data = await ((await axios.get("https://raw.githubusercontent.com/globaldothealth/monkeypox/main/latest.json")).data);
    console.log(data);
    let countries = {};
    let dates = {};
    let minDate = new Date();
    let maxDate = new Date('2020/01/01');
    let cases = 0;
    data.forEach(v => {
      let country = v.Country;
      if (!countries[country]) {
        countries[country] = 0;
      }
      countries[country] += 1;
      let formattedDate = getFormattedDate(new Date(v.Date_confirmation));
      if (selectedCountry.value === 'All' || selectedCountry.value === country) {
        let date = new Date(v.Date_confirmation);
        if (v.Status !== 'confirmed') {
          formattedDate = getFormattedDate(new Date(v.Date_entry));
          date = new Date(v.Date_entry);
        }
        if (!dates[formattedDate]) {
          dates[formattedDate] = 0;
        }
        dates[formattedDate] += 1;
        cases++;
        if (date < minDate) {
          minDate = date;
        }
        if (date > maxDate) {
          maxDate = date;
        }
      }

    })
    console.log(countries);
    setCountryCaseData(countries);
    setCountries(Object.keys(countries));
    let allDateArr = [];
    let sevenDayAverage = [];
    generateDates(minDate, maxDate).forEach(v => {
      let formattedDate = getFormattedDate(v);
      let cases = 0;
      if (dates[formattedDate]) {
        cases = dates[formattedDate]
      }
      if (sevenDayAverage.length == 7) {
        sevenDayAverage.shift()
      }
      sevenDayAverage.push(cases);
      let average = sevenDayAverage.reduce((p, v) => p + v, 0) / sevenDayAverage.length
      console.log(average);
      allDateArr.push({ date: formattedDate, cases, sevenDayAverage: average });
    });
    calculateRValue(allDateArr);
    setDateData(allDateArr);
    setCases(cases);
  }

  let getFormattedDate = (date) => {
    if (date) {
      let dDate = new Date(date);
      var year = dDate.getFullYear();

      var month = (1 + dDate.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = dDate.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }
  }

  let getShortFormattedDate = (date) => {
    if (date) {
      let dDate = new Date(date);
      var year = dDate.getFullYear().toString().substring(2);

      var month = (1 + dDate.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = dDate.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }
  }


  let calculateRValue = (data) => {
    let maxDate = data[data.length - 1].date;
    let RReal = 0;
    let daySmear = [];
    data.forEach((dateData, i) => {
      if (data.length - 30 < i) {
        let average = daySmear.reduce((a, b) => a + b, 0);
        if (average <= 0) {
          average = 1;
        }
        let R = (dateData.cases * daySmear.length) / average;
        daySmear.pop();
        daySmear.unshift(dateData.cases);
        RReal = RReal + R;
      } else {
        if (daySmear.length === 7) {
          daySmear.pop();
        }
        daySmear.unshift(dateData.cases);
      }
    });
    RReal = (RReal / 30).toFixed(2);
    console.log("rvalue", RReal)
    setRValue(RReal);
  }
  let generateDates = (d0, d1) => {
    let dates = [];
    dates[0] = d0;
    let end = d1;
    let day = 60 * 60 * 24 * 1000;
    while (dates[dates.length - 1] < end) {
      let date = new Date();
      dates.push(
        new Date(date.setTime(dates[dates.length - 1].getTime() + day))
      );
    }
    console.log(dates);
    return dates;
  }

  const [selectedCountry, setSelectedCountry] = useState({
    value: 'All', label: 'All'
  });
  const [countries, setCountries] = useState([]);
  const [countryData, setCountryCaseData] = useState({});
  const [dates, setDateData] = useState({});
  const [rValue, setRValue] = useState(null);
  const [cases, setCases] = useState(null);

  useEffect(() => {
    getMonkeyPoxData();
  }, [selectedCountry])
  console.log("selectedCountry", selectedCountry)
  console.log(dates);
  return (
    <div className="Charts">
      <div className="Chart">
        <ResponsiveContainer width="100%"
          height={600}
          styles={{ color: "white important!" }}>
          <LineChart height={250} data={dates}>
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis />
            <Tooltip />
            <Legend styles={{ marginBottom: "50px" }} />
            <Line name={`Cases`} type="monotone" dataKey="cases" stroke="#8884d8" fillOpacity={0.3}
              activeDot={{ r: 8 }} />
            <Line name={`Seven Day Average`} type="monotone" dataKey="sevenDayAverage" stroke="#8ff3d8" fillOpacity={0.3}
              activeDot={{ r: 8 }} />
            <XAxis
              styles={{ paddingBottom: "50px", fontSize: '3px important!' }}
              interval={1}
              dataKey="date"
              tickFormatter={(tick) => {
                console.log(tick)
                return getShortFormattedDate(new Date(tick));
              }}
            />
            <Tooltip
              itemSorter={(item) => item.name}
              formatter={(value, name, props) => {
                if (name === "Estimated Infection Range") {
                  let estimatedCaseRange =
                    props.payload.estimatedCaseRange;
                  return (
                    estimatedCaseRange[0].toLocaleString() +
                    " - " +
                    estimatedCaseRange[1].toLocaleString()
                  );
                } else if (
                  name === "Lower bound attack rate" ||
                  name === "Upper bound attack rate"
                ) {
                  return value.toLocaleString() + "% of population";
                } else {
                  return value.toLocaleString();
                }
              }}
              labelFormatter={(v) => {
                console.log(v)
                let d = new Date(dates[v] ? dates[v].dateChecked : "");
                let d2 = new Date(this.state.maxDate);
                d2 = new Date(
                  d2.setMinutes(d2.getMinutes() + d2.getTimezoneOffset())
                );

                return (
                  <span style={{ color: "black" }}>
                    {d > d2 && <>{"Projection For: "}</>}
                    {this.getFormattedDate(d)}
                  </span>
                );
              }}
            />

          </LineChart>

        </ResponsiveContainer>
      </div >
      <DataBar rValue={rValue} cases={cases} />
      <Selector countries={countries} setSelectedCountry={setSelectedCountry} selectedCountry={selectedCountry} />

    </div >
  );
}

export default Charts;
